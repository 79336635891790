import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';
import { useCurrentStateAndParams } from '@wix/tpa-router/react';

import { groupsSearchSearchRequestSrc111Evid522 } from '@wix/bi-logger-groups-data/v2';

import { Divider } from 'wui/Divider';
import { TextField } from 'wui/TextField';
import { DialogTitle } from 'wui/DialogTitle';
import { DialogContent } from 'wui/DialogContent';
import { DialogContainer } from 'wui/DialogContainer';
import { Drawer, type IDrawerProps } from 'wui/Drawer';
import { Search as SearchIcon } from '@wix/wix-ui-icons-common/on-stage';

import { useDidUpdate } from 'common/hooks';

import { SearchResults } from './SearchResults';

interface IProps {
  groupId: string;
  origin: string;
}

export function GroupSearch(props: IProps & IDrawerProps) {
  const { groupId, isOpen, onClose, origin, ...rest } = props;

  const bi = useBi();
  const { t } = useTranslation();
  const { params } = useCurrentStateAndParams();

  const $container = useRef<HTMLDivElement>(null);
  const [query, setQuery] = React.useState<string>();

  const handleQueryChange = useCallback(
    debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value);
      bi.report(
        groupsSearchSearchRequestSrc111Evid522({
          groupId,
          origin: props.origin,
          inputText: event.target.value,
          tabName: 'posts',
        }),
      );
    }, 500),
    [],
  );

  useEffect(() => {
    if (!isOpen) {
      setQuery(undefined);
    }
  }, [isOpen]);

  // autoclose on navigation
  useDidUpdate(() => {
    onClose();
  }, [params.feedItemId]);

  return (
    <Drawer
      anchor="right"
      isOpen={isOpen}
      onClose={onClose}
      hideCloseButton={false}
      aria-labelledby="gs-dialog-title"
      {...rest}
    >
      <DialogContainer>
        <DialogTitle
          id="gs-dialog-title"
          title={t('groups-web.group-search.modal.title')}
        />

        <Divider bw />

        <DialogContent scrollable={false}>
          <TextField
            autoComplete="off"
            theme="line"
            value={query}
            withClearButton
            prefix={<SearchIcon />}
            onClear={handleReset}
            onChange={handleQueryChange}
            placeholder={t('groups-web.group-search.placeholder')}
          />
        </DialogContent>

        <DialogContent ref={$container} scrollable disableSideGutters>
          <SearchResults
            query={query}
            groupId={groupId}
            onReset={handleReset}
            container={$container.current}
            origin={origin}
          />
        </DialogContent>
      </DialogContainer>
    </Drawer>
  );

  function handleReset() {
    setQuery(undefined);
  }
}

GroupSearch.displayName = 'GroupSearch';
