import React from 'react';

import { useSelector } from 'react-redux';
import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useCurrentStateAndParams } from '@wix/tpa-router/react';

import { selectGroupIdBySlug } from 'store/selectors';
import { Container, type IContainerProps } from 'wui/Container';

import { CoverImageLayout } from 'settings/consts';
import { settings as settingsParams } from 'settings/group';

import {
  LargeImageLayout,
  MobileLayout,
  ThumbnailImageLayout,
} from './layouts';

export function Header(props: IContainerProps) {
  const { params } = useCurrentStateAndParams();
  const environment = useEnvironment();
  const settings = useSettings();

  const groupId = useSelector(selectGroupIdBySlug(params.slug));
  const layout = settings.get(settingsParams.coverImageLayout);
  const hasImage = layout !== CoverImageLayout.blank;

  if (environment.isMobile) {
    return (
      <Container fluid {...props}>
        <MobileLayout withImage={hasImage} groupId={groupId} />
      </Container>
    );
  }

  switch (layout) {
    case CoverImageLayout.large:
      return (
        <Container fluid data-layout-type="large" {...props}>
          <LargeImageLayout groupId={groupId} />
        </Container>
      );

    case CoverImageLayout.thumbnail:
    case CoverImageLayout.blank:
    default:
      return (
        <Container data-layout-type={layout} {...props}>
          <ThumbnailImageLayout withImage={hasImage} groupId={groupId} />
        </Container>
      );
  }
}

Header.displayName = 'Header';
