import React, { useEffect } from 'react';

import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import {
  Facebook as FacebookIcon,
  Link as LinkIcon,
  LinkedIn as LinkedInIcon,
  XCorp as TwitterIcon,
} from '@wix/wix-ui-icons-common/on-stage';
import { CopyUrlButton } from 'wix-ui-tpa/cssVars';
import { IconButton } from 'wui/IconButton';

import { Box } from '../Box';
import { Show } from '../Show';
import { Drawer } from '../Drawer';
import { AlertDialog } from '../AlertDialog';
import { DialogTitle } from '../DialogTitle';
import { DialogContent } from '../DialogContent';

interface IProps extends React.ComponentProps<typeof AlertDialog> {
  data: ShareData;
  title?: string;
  canUseNative?: boolean;
}

export function ShareDialog(props: IProps) {
  const { canUseNative, title, data, ...rest } = props;

  const { isMobile, isSSR } = useEnvironment();
  const { t } = useTranslation();

  const useNative = !isSSR && !!navigator.share && canUseNative;

  useEffect(() => {
    if (!props.isOpen || !useNative) {
      return;
    }

    navigator.share(data).finally(() => props.onClose?.());
  }, [props.isOpen]);

  if (useNative) {
    return null;
  }

  const url = encodeURIComponent(data.url as string);

  const facebookUrl =
    `https://facebook.com/sharer/sharer.php?u=${url}` as const;
  const twitterUrl = `https://twitter.com/intent/tweet?url=${url}` as const;
  const linkedInUrl =
    `https://linkedin.com/sharing/share-offsite/?url=${url}` as const;

  if (isMobile) {
    return (
      <Drawer {...rest} aria-labelledby="share-dialog-title">
        <Box padding="SP9 SP6" gap="SP5" direction="vertical">
          <Show if={!!title}>
            <DialogTitle alert id="share-dialog-title" title={title} />
          </Show>
          <Box gap="SP5" align="center" verticalAlign="middle">
            <IconButton
              as="a"
              icon={<FacebookIcon />}
              href={facebookUrl}
              aria-label={t('groups-web.a11y.share.facebook')}
            />
            <IconButton
              as="a"
              icon={<TwitterIcon />}
              href={twitterUrl}
              aria-label={t('groups-web.a11y.share.twitter')}
            />
            <IconButton
              as="a"
              icon={<LinkedInIcon />}
              href={linkedInUrl}
              aria-label={t('groups-web.a11y.share.linkedin')}
            />
            <CopyUrlButton
              icon={<LinkIcon aria-label={t('groups-web.a11y.copy-link')} />}
              url={data.url as string}
              successText="Link Copied"
              tooltipText={undefined as any}
            />
          </Box>
        </Box>
      </Drawer>
    );
  }

  return (
    <AlertDialog {...rest}>
      <Show if={!!title}>
        <DialogTitle alert title={title} />
      </Show>
      <DialogContent alert>
        <Box gap="SP10" align="center" verticalAlign="middle">
          <IconButton
            as="a"
            target="_blank"
            icon={<FacebookIcon size={24} />}
            href={facebookUrl}
            aria-label={t('groups-web.a11y.share.facebook')}
          />
          <IconButton
            as="a"
            target="_blank"
            icon={<LinkedInIcon size={24} />}
            href={linkedInUrl}
            aria-label={t('groups-web.a11y.share.linkedin')}
          />
          <IconButton
            as="a"
            target="_blank"
            icon={<TwitterIcon size={24} />}
            href={twitterUrl}
            aria-label={t('groups-web.a11y.share.twitter')}
          />
          <Box width={24} align="center">
            <CopyUrlButton
              icon={
                <LinkIcon
                  size={24}
                  aria-label={t('groups-web.a11y.copy-link')}
                />
              }
              url={data.url as string}
              successText={undefined as any}
              tooltipText={undefined as any}
            />
          </Box>
        </Box>
      </DialogContent>
    </AlertDialog>
  );
}

ShareDialog.displayName = 'wui/ShareDialog';
ShareDialog.defaultProps = {
  canUseNative: true,
};
