import React, { useEffect } from 'react';
import { SwitchTransition } from 'react-transition-group';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { Show } from 'wui/Show';
import { Fade } from 'wui/Fade';
import { Drawer } from 'wui/Drawer';
import { DialogContainer } from 'wui/DialogContainer';
import { DialogTitle } from 'wui/DialogTitle';
import { Tabs } from 'wui/Tabs';

import { GROUP_ACTIONS_SIDE_PANEL } from '../dataHooks';

import { ApplicationsTab, DetailsTab, ModerateTab } from './Tabs';

interface IGroupSettingsProps {
  isOpen: boolean;
  groupId: string;

  onClose(): void;
}

export function GroupSettings(props: IGroupSettingsProps) {
  const { groupId, isOpen, onClose } = props;

  const { t } = useTranslation();

  const [activeTab, setActiveTab] = React.useState('details');

  useEffect(() => {
    if (!isOpen) {
      setActiveTab('details');
    }
  }, [isOpen]);

  return (
    <Drawer
      hideCloseButton={false}
      data-hook={GROUP_ACTIONS_SIDE_PANEL}
      isOpen={isOpen}
      onClose={handleClose}
      anchor="right"
      aria-labelledby="gs-dialog-title"
    >
      <DialogContainer>
        <DialogTitle
          id="gs-dialog-title"
          title={t('groups-web.group.settings')}
        />

        <Tabs
          variant="fullWidth"
          value={activeTab}
          onChange={setActiveTab}
          skin="fullUnderline"
          items={[
            {
              id: 'details',
              title: t('groups-web.group.settings.details'),
            },
            {
              id: 'apps',
              title: t('groups-web.group.settings.tabs'),
            },
            {
              id: 'settings',
              title: t('groups-web.group.settings.moderate'),
            },
          ]}
        />

        <SwitchTransition>
          <Fade key={activeTab}>
            <>
              <Show if={activeTab === 'details'}>
                <DetailsTab groupId={groupId} onClose={handleClose} />
              </Show>
              <Show if={activeTab === 'apps'}>
                <ApplicationsTab groupId={groupId} onClose={handleClose} />
              </Show>
              <Show if={activeTab === 'settings'}>
                <ModerateTab groupId={groupId} />
              </Show>
            </>
          </Fade>
        </SwitchTransition>
      </DialogContainer>
    </Drawer>
  );

  function handleClose() {
    onClose();
  }
}

GroupSettings.displayName = 'GroupSettings';
